// import {useEffect} as React from 'react'
import React, { useState, useEffect } from 'react';
import logo from '../images/logo.png';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Buscar from '../buscar/Buscar';
// import 'https://api-tool.tabsa.cl/storage/estilos.css'
import axios from 'axios';
// import styles from './Encomienda.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import  { Redirect } from 'react-router-dom'

function App(props) {

    const { documento } = useParams()
    const { encomienda } = useParams()
    const url = 'https://api-carga.tabsa.cl/api/encomienda/estado'
    const [prueba, setPrueba] = useState([]);
    var n = 0
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const encomienda_ = useFormInput('');
    const documento_ = useFormInput('');


    const handleEncomienda = () => {
    const data = {
      encomienda : encomienda_.value,
      rut : documento_.value
    }

    const headers = { 
    };
    console.log('dirección')
    axios.post(url, data, { headers }).then(response => {
        console.log('respuesta')
        console.log(response)
        if(response.data.data){
            window.location.href ='https://seguimiento.tabsa.cl/'+documento_.value+'/'+encomienda_.value
        }else{
            toast.error('No hay resultados para esta búsqueda.', {
                position: toast.POSITION.BOTTOM_CENTER
            });
            // console.log(response.data);
            // window.location.reload();
        }
    })
    console.log(axios.response);
    // console.log(getUserSession);
    // if(getUserSession().auth === 0){
    //   props.history.push('/');
    // }

}

    useEffect(() => {
        const data = {
            encomienda: encomienda,
            rut: documento
        }
        axios.post(url, data).then(
            response => {
                const resp = response.data
                setPrueba(resp.data)
                console.log(response.data.data.length)
            }
        )
        prueba.forEach(prueba => {
            console.log('post')
            console.log(`índice ${n}`)
            n++
        });
	},[])
    
    

    return (
        <div>
            <Buscar/>
              <ToastContainer />
                    <br/><br/><br/><br/>
                    <center>
                        <table>
                            <thead >
                                <tr>
                                    <th className="th-black">Ítem</th>
                                    <th className="th-black">Estado</th>
                                    <th className="th-black">Última Actualización</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(prueba).map((i) => (
                                    <tr key={i}>
                                        <td  className='td-black'>
                                            {prueba[i].item}
                                        </td>
                                        <td className='td-black'>
                                            {prueba[i].descripcion}
                                        </td>
                                        <td className='td-black'>
                                            {prueba[i].last_update.substring(0,19)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </center>
                </div>
    );
  }
  const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);
  
    const handleChange = e => {
      setValue(e.target.value);
    }
    return {
      value,
      onChange: handleChange
    }
  }
  export default App;