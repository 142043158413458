import logo from '../images/logo.png';
import React, { useState } from 'react';
// import './Buscar.css';
// import 'https://api-tool.tabsa.cl/storage/estilos.css'
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Encomienda from '../encomienda/Encomienda'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Buscar(props) {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const url = 'https://api-carga.tabsa.cl/api/encomienda/estado'
    const encomienda = useFormInput('');
    const documento = useFormInput('');


  const handleEncomienda = () => {
    const data = {
      encomienda : encomienda.value,
      rut : documento.value
    }

    const headers = { 
    };
    axios.post(url, data, { headers }).then(response => {   
      if(response.data.data){
        navigate('/'+documento.value+'/'+encomienda.value);
      }else{
        toast.error('No hay resultados para esta búsqueda.', {
            position: toast.POSITION.BOTTOM_CENTER
        });
        // console.log(response.data);
        // window.location.reload();
      }
    })
    console.log(axios.response);
    // console.log(getUserSession);
    // if(getUserSession().auth === 0){
    //   props.history.push('/');
    // }

  }


    return (
        <>
          {/* <body> */}
            <center>
              <div className='container' >
                {/* <div className='card'> */}
                  {/* <h2 style={{color: "black", textAlign:"center"}}>Buscar Encomienda Puerto Williams</h2><br /> */}
                  <h2>Buscar Encomienda Puerto Williams</h2><br />

                  {/* <h2 >INICIAR SESIÓN</h2> */}
                  {/* <center> */}
                    {/* <div style={{backgroundColor: "#f8f9fa", width: "400px", marginLeft:"25px", borderColor: "gray", border:"2px"}}> */}
                      <div>
                          <label style={{color: "black"}}>Documento de Identificación</label><br/><br/>
                          {/* <input style={{width:"180px", marginLeft:"41px"}} type="text"  className='form' {...documento}  required/> */}
                          <center>
                            <input type="text" {...documento}  required/>
                          </center>
                      </div>
                      <br/>
                      <div>
                          <label style={{color: "black"}}>Nº de Encomienda</label><br /><br/>
                            {/* <input style={{width:"180px"}} type="number" className='form' {...encomienda} required/> */}
                            <center>
                              <input type="number" {...encomienda} required/>
                            </center>
                      </div><br/><br/>
                    {/* </div> */}
                    <center>
                      {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}
                      <input type="button" className='btn2' value={loading ? 'Buscando...' : 'Buscar'} onClick={handleEncomienda} disabled={loading} /><br />
                      <ToastContainer />
                      <br/>
                    </center>
                  {/* </center> */}
                {/* </div> */}
              </div>
            </center>
          {/* </body> */}
      </>
    );

}

  const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);
  
    const handleChange = e => {
      setValue(e.target.value);
    }
    return {
      value,
      onChange: handleChange
    }
  }

export default Buscar;
