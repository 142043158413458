import logo from './images/logo.png';
import React, { useState } from 'react';
// import './App.css';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Encomienda from './encomienda/Encomienda'
import Buscar from './buscar/Buscar'
import { BrowserRouter, Routes, Route, NavLink, Router } from 'react-router-dom';

function App() {


  return (
    <>
      <head>
        <link rel="stylesheet" type="text/css" href="https://api-tool.tabsa.cl/storage/estilos.css"></link>
      </head>
      <header>
        <img src={logo} className="logo"></img>
    </header>
    <br/>
        <BrowserRouter>
            <Routes>
              <Route exact path="/:documento/:encomienda" element={<Encomienda/>} />
              <Route exact path="/" element={<Buscar/>} />
            </Routes>
        </BrowserRouter>
    </>

  );

}

export default App;
